<template>
  <div
    class="page flex-col"
    ref="page"
    :style="
      hostname == 'demo.daping.cn'
        ? 'height: calc(100vh - 120rem)'
        : 'height: calc(100vh - 70rem)'
    "
    v-infinite-scroll="adHistoryLoad"
    infinite-scroll-distance="1"
    infinite-scroll-immediate="false"
  >
    <div class="flex-row justify-center">
      <div class="left-view flex-col">
        <span>历史投放记录</span>
        <div :class="adListScreenList.length == 1 ? 'tab' : 'tabs'">
          <div
            v-for="(item, index) in adListScreenList"
            :key="index"
            class="tab-item"
            @mouseenter="mouseenterMode(index)"
            @mouseleave="mouseleaveMode"
            @click="changeScreen(item, index)"
          >
            {{ item.remark }}
          </div>
          <div class="border-left" :style="'height:' + borderLeftHeight"></div>
          <div class="slider" :style="{ top: sliderTop }"></div>
        </div>
      </div>
      <div v-if="adHistoryList.length > 0" class="right-view flex-row flex-wrap">
        <div
          class="adList-item flex-col"
          :style="isFirefox ? 'width:252rem' : ''"
          v-for="(item, index) in adHistoryList"
          :key="item.adId"
          @click.stop="handleClickSlide(item, index)"
        >
          <div class="videoThumbnailUrl flex-col">
            <img class="pic" :src="item.videoThumbnailUrl" />
            <img class="icon" src="@/assets/img/play_icon.png" />
          </div>
          <div class="releaseUser-view flex-row align-center">
            <img v-if="item.userImage" :src="item.userImage" alt="" />
            <img v-else src="@/assets/img/default_head.png" alt="" />
            <span>{{ item.userName }}</span>
          </div>
          <div class="release-beginUnix flex-row align-center">
            <span> {{ item.beginUnix | dateFormat }}</span>
          </div>
        </div>
        <p v-if="loading" class="loading">加载中</p>
        <p
          v-else-if="noMore && total >= 16"
          class="noMore"
        >
          只显示最近320条。
        </p>
        <p v-else class="noMore"></p>
      </div>
      <div class="right-view flex-row justify-center align-center" v-else>暂无数据</div>
    </div>
    <Footer v-if="noMore"></Footer>
    <el-backtop target=".page" :visibility-height="350">
      <div class="backtop flex-row justify-center align-center">
        <svg-icon class="gotop-svg" iconClass="gotop"></svg-icon>
      </div>
    </el-backtop>
    <pre-video-dialog
      ref="refPreVideoDialog"
      :videoData="preVideoData"
      :length="adHistoryList.length"
      @dialogPrev="dialogPrev"
      @dialogLower="dialogLower"
    ></pre-video-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { getListAdHistory } from "@/api/request";
import Footer from "@/layout/components/footer.vue";
import preVideoDialog from "@/components/homePreVideoDialog.vue";

export default {
  components: {
    preVideoDialog,
    Footer,
  },
  data() {
    return {
      adHistoryList: [],
      currentScreenName: "", // 历史投放广告初始化默认屏幕
      currentScreenIndex: 0,
      adListCurrentIndex: 0,
      sliderTop: "6rem",
      loadMultiple: 0,
      isFirefox: false, // 是否是火狐浏览器
      pageLimit: {
        limit: 16,
        page: 1
      },
      total: 0,
      allPages: "", // 分页总页数
      preVideoData: {
        dialogUrl: "",
        dialogTitle: "",
        beginUnix: null,
        currentScreen: ""
      },
    };
  },
  created() {
    // 如果存在大屏（大屏没有下架）
    if (this.adListScreenList && this.adListScreenList.length) {
      this.currentScreenName = this.adListScreenList[0].name;
      this.getListAdHistory();
    }
  },
  mounted() {
    // 如果是火狐浏览器，解决样式多1px不兼容的问题
    if (navigator.userAgent.indexOf("Firefox") > -1) {
      this.isFirefox = true;
    }
  },
  computed: {
    // 历史投放广告屏幕列表
    adListScreenList() {
      if (this.$store.state.screenList.length) {
        // 过滤已下架（down为true）的屏幕
        const currentScreenList = this.$store.state.screenList.filter(
          (v) => !v.down
        );
        return currentScreenList;
      }
      return [];
    },
    borderLeftHeight() {
      const height = `${(this.adListScreenList.length - 1) * 50 + 16}rem`;
      return height;
    },
    noMore() {
      return this.adHistoryList.length >= this.total;
    },
    loading() {
      return this.adHistoryList.length < this.total;
    },
    hostname() {
      return window.location.hostname;
    },
  },
  methods: {
    // 获取历史投放
    getListAdHistory() {
      getListAdHistory({
        name: this.currentScreenName,
        pageLimit: { ...this.pageLimit }
      }).then((res) => {
        if (res.pageResult.total <= 320) {
          this.allPages = res.pageResult.pages;
        } else {
          res.pageResult.total = 320;
          this.allPages = 20;
        }
        this.total = res.pageResult.total;
        if (this.pageLimit.page == 1) {
          this.adHistoryList = res.adHistoryList;
        } else {
          this.adHistoryList = [...this.adHistoryList, ...res.adHistoryList];
        }
      });
    },
    // 点击单个历史投放广告
    handleClickSlide(item, index) {
      this.adListCurrentIndex = index;
      this.preVideoData.dialogUrl = `${item.videoUrl}?${new Date().getTime()}`;
      this.preVideoData.dialogTitle = item.userName;
      this.preVideoData.beginUnix = item.beginUnix;
      this.preVideoData.currentScreen = item.name;
      this.$refs.refPreVideoDialog.openDialog();
    },
    // 点击上一个
    dialogPrev() {
      let prevIndex = null;
      if (this.adListCurrentIndex == 0) {
        prevIndex = this.adHistoryList.length - 1;
      } else {
        prevIndex = this.adListCurrentIndex - 1;
      }
      this.preVideoData.dialogUrl = `${
        this.adHistoryList[prevIndex].videoUrl
      }?${new Date().getTime()}`;
      this.preVideoData.dialogTitle = this.adHistoryList[prevIndex].userName;
      this.preVideoData.beginUnix = this.adHistoryList[prevIndex].beginUnix;
      this.preVideoData.currentScreen = this.adHistoryList[prevIndex].currentScreen;
      this.adListCurrentIndex = prevIndex;
    },
    // 点击下一个
    dialogLower() {
      let lowerIndex = null;
      if (this.adListCurrentIndex == this.adHistoryList.length - 1) {
        lowerIndex = 0;
      } else {
        lowerIndex = this.adListCurrentIndex + 1;
      }
      this.preVideoData.dialogUrl = `${
        this.adHistoryList[lowerIndex].videoUrl
      }?${new Date().getTime()}`;
      this.preVideoData.dialogTitle = this.adHistoryList[lowerIndex].userName;
      this.preVideoData.beginUnix = this.adHistoryList[lowerIndex].beginUnix;
      this.preVideoData.currentScreen = this.adHistoryList[lowerIndex].currentScreen;
      this.adListCurrentIndex = lowerIndex;
    },
    mouseenterMode(index) {
      this.sliderTop = `${index * 50 + 6}rem`;
    },
    mouseleaveMode() {
      this.sliderTop = `${this.currentScreenIndex * 50 + 6}rem`;
    },
    changeScreen(item, index) {
      if (this.currentScreenIndex == index) return;
      this.currentScreenIndex = index;
      this.currentScreenName = item.name;
      this.pageLimit.page = 1;
      this.getListAdHistory();
    },
    adHistoryLoad() {
      if (this.pageLimit.page < this.allPages) {
        this.pageLimit.page++;
        console.log(
          "触发了下拉到底，进行分页请求，请求页码为",
          this.pageLimit.page
        );
        this.getListAdHistory();
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.page {
  width: 100%;
  background: #fff;
  overflow-y: auto;
  margin: 0 auto;
  .el-backtop {
    width: 50rem;
    height: 50rem;
    right: 40rem !important;
    bottom: 240rem !important;
    box-shadow: none;
    background: #fff;
    border: 1rem solid #dedede;
    .el-icon-arrow-up {
      transition: all 0.3s;
      color: #42b6fb;
    }
    .gotop-svg {
      width: 48rem;
      height: 48rem;
      color: #42b6fb;
    }
    &:hover {
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      border: none;
      .el-icon-arrow-up {
        color: #fff;
      }
      .gotop-svg {
        color: #fff;
      }
    }
  }
  .left-view {
    margin: 48rem 83rem 0 0;
    span {
      font-size: 16rem;
      color: #afb0b0;
      line-height: 22rem;
    }
    .tabs,
    .tab {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 28rem;
      position: relative;
      .tab-item {
        font-size: 20rem;
        color: #252927;
        line-height: 28rem;
        padding-left: 14rem;
        margin-top: 22rem;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: #8a8a8a;
        }
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      .border-left {
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        top: 6rem;
        left: 0;
      }
      .slider {
        position: absolute;
        left: -2rem;
        width: 3rem;
        height: 17rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
        transition: all 0.3s ease;
      }
    }
    .tab {
      .tab-item {
        cursor: auto;
        &:hover {
          color: #252927;
        }
      }
    }
  }
  .right-view {
    width: 1060rem;
    border-radius: 6rem;
    margin-top: 80rem;
    min-height: calc(100vh - 196rem);
    .adList-item {
      width: 253rem;
      height: 232rem;
      background: #f6f7f9;
      border-radius: 8rem;
      margin: 20rem 16rem 0 0;
      overflow: hidden;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .videoThumbnailUrl {
        z-index: 94;
        width: 100%;
        position: relative;

        .pic {
          height: 160rem;
          border-radius: 6rem 6rem 0 0;
          object-fit: cover;
        }
        .icon {
          width: 50rem;
          height: 50rem;
          position: absolute;
          top: 55rem;
          left: 102rem;
          display: none;
        }
        &:hover {
          .icon {
            display: block;
          }
        }
      }

      .releaseUser-view {
        margin: 14rem 0 0 12rem;
        span {
          font-size: 14rem;
          font-weight: 500;
          line-height: 20rem;
          color: #252927;
        }

        img {
          width: 22rem;
          height: 22rem;
          object-fit: cover;
          margin: 0 7rem 0 3rem;
          border-radius: 4rem;
        }
      }

      .release-beginUnix {
        margin: 6rem 0 12rem 14rem;

        span {
          font-size: 14rem;
          font-weight: 500;
          line-height: 20rem;
          color: #969898;
        }
      }
    }
    .noMore,
    .loading {
      width: 100%;
      height: 120rem;
      text-align: center;
      line-height: 120rem;
      margin: 0;
      color: #959796;
    }
  }
}
</style>
